.mobile-menu {
  display: none;
  position: fixed;
  top: 1.5rem;
  color: black;
  right: 2rem;
  z-index: 1000;
  cursor: pointer;

  > img {
    display: block;
    height: 3rem;
    width: auto;
  }
}

@media screen and (max-width: 1024px) {
  .mobile-menu {
    display: block;
  }
}