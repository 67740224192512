@import '../../assets/scss/variable.scss';

.login-container {
  text-align: center;
  max-width: 30rem;
  width: 100%;

  h2 {
      margin: 0 0 5rem 0;
  }

  form {
    .input-container {
      position: relative;
      padding: 0 0 3rem 0;
      margin-bottom: 2rem;

      input {
        display: block;
        width: 100%;
        border: 0;
        border-bottom: .1rem solid black;
        line-height: 2rem;
        background: transparent;
      }

      .error-message {
        position: absolute;
        bottom: 0;
        left: 0;
        color: red;
      }
    }

    button {
      margin: 1rem 0 0 0;
    }
  }
}