@import '../../assets/scss/variable.scss';

.main-content {
  position: relative;

    .upload-content-container {
      text-align: center;
      color: $primary;
      width: auto;
      img {
          display: block;
          width: auto;
          height: auto;
          max-width: 45rem;
          margin: 0 auto;
      }
      .txt-separator {
          margin: 1rem 0;
      }
    }

    .main-content-container  {
      position: relative;
      .lds-roller {
        position: absolute;
        bottom: 5rem;
      }
    }
}

@media screen and (max-width: 768px) {
  .main-content {
    display: block!important;

    .main-content-container {
      margin: 0;
      height: auto;
      background: transparent;
      padding: 3rem 0 3.5rem 0;

      .drag-and-drop-container {
        display: none;
      }

      img {
        max-width: 100%!important;
      }
    }
  }
}