@import '../../assets/scss/variable.scss';

.sidenav {
    background-color: $primary;
    width: $sidebarWidth;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;

    img.logo {
        display: block;
        text-align: center;
        width: auto;
        height: auto;
        max-width: 20rem;
        margin: 5rem auto 8rem auto;
    }

    ul {
        margin: 0;
        padding: 0;
        float: right;
        list-style: none;
        width: 20rem;

        li {
            margin: 1rem 0;
            background-color: #ffffff21;
            border-top-left-radius: 5rem;
            border-bottom-left-radius: 5rem;

            a {
                padding: 2rem;
                color: white;
                font-size: 1.4rem;
                text-decoration: none;
                display: block;

                img {
                    width: auto;
                    height: auto;
                    max-width: 2.25rem;
                    vertical-align: middle;
                    margin-right: 1.5rem;
                }
            }
        }
    }
}

@media screen and (max-width: 1024px) {
  .sidenav {
    display: none;
    background-color: white;
    width: 100%;
    z-index: 999;

    img.logo {
      margin: 5rem auto 6rem auto;
    }

    ul {
      width: 100%;
      float: none;
      display: block;
      height: 100%;

      li {
        border-radius: 0;
        background-color: $grey;

        a {
          text-align: center;
          color: black;

          img {
            display: none;
          }
        }
      }
    }
  }
}