@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');
@import './variable.scss';

html, body {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}
*:focus {
  outline: none;
}

html {
  font-size: 62.5%;

  body {
    font-family: 'Open Sans', sans-serif;
    font-size: 1.5rem;
    background-color: $grey;

    @import './components/index.scss';
  }
}