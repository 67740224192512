@import '../../assets//scss/variable.scss';

.aside-content-container {
    width: 45rem;
    margin-right: 5rem;
    padding: 1.5rem;
    height: calc(100vh - 10rem);
    word-break: break-all;
    overflow: auto;

    h2 {
        color: $primary;
        text-align: center;
        margin: 0 0 2rem 0;
        font-size: 2rem;
    }

    input {
      display: block;
      width: 100%;
      border: 0;
      border-bottom: .1rem solid black;
      line-height: 2rem;
    }

    ul {
        padding: 0;
        margin: 3.5rem 0 0 0;
        list-style: none;

        li {
            margin: 1.5rem 0;

            a {
                font-size: 1.25rem;
                color: grey;
                text-decoration: none;
                transition: color .3s ease-in-out;
                align-items: end;
                &:hover {
                    color: $primary;
                }
                
                img {
                    display: inline-block;
                    width: 2.5rem;
                    vertical-align: middle;
                    margin-right: 1rem;
                }
            }
        }
    }
}

@media screen and (max-width: 1024px) {
  .aside-content-container {
    margin: 0 1.5rem 0 0;
  }
}


@media screen and (max-width: 768px) {
  .aside-content-container  {
    height: auto;
    width: 100%;
    overflow: hidden;
    margin-bottom: 3.5rem;
  }
}