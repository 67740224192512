@import url(https://fonts.googleapis.com/css?family=Open+Sans&display=swap);
html, body {
  margin: 0;
  padding: 0; }

* {
  box-sizing: border-box; }

*:focus {
  outline: none; }

html {
  font-size: 62.5%; }
  html body {
    font-family: 'Open Sans', sans-serif;
    font-size: 1.5rem;
    background-color: #f8f7fb; }
    html body button.btn {
      border: 0;
      padding: 1.25rem 5rem;
      background-color: #6f51ff;
      color: white;
      cursor: pointer;
      font-weight: bold;
      border-radius: 5rem;
      display: inline-block;
      width: auto;
      transition: opacity .3s ease-in-out; }
      html body button.btn:focus {
        outline: none; }
      html body button.btn:hover {
        opacity: .7; }
    html body button.btn.has-icon img {
      width: 2rem;
      height: auto;
      display: inline-block;
      vertical-align: middle;
      margin-right: 1rem; }
    html body .container {
      width: 100%;
      padding: 0 1.5rem; }
    html body .d-flex {
      display: flex; }
    html body .align-center {
      align-items: center; }
    html body .justify-center {
      justify-content: center; }
    html body .main-content {
      padding-left: 25rem;
      width: 100%;
      background-color: #f8f7fb;
      height: auto;
      min-height: 100vh; }
    html body .main-content-container {
      height: calc(100vh - 10rem);
      width: 100%;
      margin: 0 5rem; }
    html body .is-card {
      background-color: white;
      border-radius: 5rem; }
    html body .lds-roller {
      display: inline-block;
      position: relative;
      width: 80px;
      height: 80px; }
    html body .lds-roller div {
      -webkit-animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
              animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      transform-origin: 40px 40px; }
    html body .lds-roller div:after {
      content: " ";
      display: block;
      position: absolute;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background: #6045e2;
      margin: -4px 0 0 -4px; }
    html body .lds-roller div:nth-child(1) {
      -webkit-animation-delay: -0.036s;
              animation-delay: -0.036s; }
    html body .lds-roller div:nth-child(1):after {
      top: 63px;
      left: 63px; }
    html body .lds-roller div:nth-child(2) {
      -webkit-animation-delay: -0.072s;
              animation-delay: -0.072s; }
    html body .lds-roller div:nth-child(2):after {
      top: 68px;
      left: 56px; }
    html body .lds-roller div:nth-child(3) {
      -webkit-animation-delay: -0.108s;
              animation-delay: -0.108s; }
    html body .lds-roller div:nth-child(3):after {
      top: 71px;
      left: 48px; }
    html body .lds-roller div:nth-child(4) {
      -webkit-animation-delay: -0.144s;
              animation-delay: -0.144s; }
    html body .lds-roller div:nth-child(4):after {
      top: 72px;
      left: 40px; }
    html body .lds-roller div:nth-child(5) {
      -webkit-animation-delay: -0.18s;
              animation-delay: -0.18s; }
    html body .lds-roller div:nth-child(5):after {
      top: 71px;
      left: 32px; }
    html body .lds-roller div:nth-child(6) {
      -webkit-animation-delay: -0.216s;
              animation-delay: -0.216s; }
    html body .lds-roller div:nth-child(6):after {
      top: 68px;
      left: 24px; }
    html body .lds-roller div:nth-child(7) {
      -webkit-animation-delay: -0.252s;
              animation-delay: -0.252s; }
    html body .lds-roller div:nth-child(7):after {
      top: 63px;
      left: 17px; }
    html body .lds-roller div:nth-child(8) {
      -webkit-animation-delay: -0.288s;
              animation-delay: -0.288s; }
    html body .lds-roller div:nth-child(8):after {
      top: 56px;
      left: 12px; }

@-webkit-keyframes lds-roller {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes lds-roller {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }
    @media screen and (max-width: 1024px) {
      html body .main-content {
        padding: 0; } }
    @media screen and (max-width: 768px) {
      html body .main-content {
        padding: 0 2rem; } }

.main-content {
  position: relative; }
  .main-content .upload-content-container {
    text-align: center;
    color: #6f51ff;
    width: auto; }
    .main-content .upload-content-container img {
      display: block;
      width: auto;
      height: auto;
      max-width: 45rem;
      margin: 0 auto; }
    .main-content .upload-content-container .txt-separator {
      margin: 1rem 0; }
  .main-content .main-content-container {
    position: relative; }
    .main-content .main-content-container .lds-roller {
      position: absolute;
      bottom: 5rem; }

@media screen and (max-width: 768px) {
  .main-content {
    display: block !important; }
    .main-content .main-content-container {
      margin: 0;
      height: auto;
      background: transparent;
      padding: 3rem 0 3.5rem 0; }
      .main-content .main-content-container .drag-and-drop-container {
        display: none; }
      .main-content .main-content-container img {
        max-width: 100% !important; } }

.sidenav {
  background-color: #6f51ff;
  width: 25rem;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0; }
  .sidenav img.logo {
    display: block;
    text-align: center;
    width: auto;
    height: auto;
    max-width: 20rem;
    margin: 5rem auto 8rem auto; }
  .sidenav ul {
    margin: 0;
    padding: 0;
    float: right;
    list-style: none;
    width: 20rem; }
    .sidenav ul li {
      margin: 1rem 0;
      background-color: #ffffff21;
      border-top-left-radius: 5rem;
      border-bottom-left-radius: 5rem; }
      .sidenav ul li a {
        padding: 2rem;
        color: white;
        font-size: 1.4rem;
        text-decoration: none;
        display: block; }
        .sidenav ul li a img {
          width: auto;
          height: auto;
          max-width: 2.25rem;
          vertical-align: middle;
          margin-right: 1.5rem; }

@media screen and (max-width: 1024px) {
  .sidenav {
    display: none;
    background-color: white;
    width: 100%;
    z-index: 999; }
    .sidenav img.logo {
      margin: 5rem auto 6rem auto; }
    .sidenav ul {
      width: 100%;
      float: none;
      display: block;
      height: 100%; }
      .sidenav ul li {
        border-radius: 0;
        background-color: #f8f7fb; }
        .sidenav ul li a {
          text-align: center;
          color: black; }
          .sidenav ul li a img {
            display: none; } }

.aside-content-container {
  width: 45rem;
  margin-right: 5rem;
  padding: 1.5rem;
  height: calc(100vh - 10rem);
  word-break: break-all;
  overflow: auto; }
  .aside-content-container h2 {
    color: #6f51ff;
    text-align: center;
    margin: 0 0 2rem 0;
    font-size: 2rem; }
  .aside-content-container input {
    display: block;
    width: 100%;
    border: 0;
    border-bottom: .1rem solid black;
    line-height: 2rem; }
  .aside-content-container ul {
    padding: 0;
    margin: 3.5rem 0 0 0;
    list-style: none; }
    .aside-content-container ul li {
      margin: 1.5rem 0; }
      .aside-content-container ul li a {
        font-size: 1.25rem;
        color: grey;
        text-decoration: none;
        transition: color .3s ease-in-out;
        align-items: end; }
        .aside-content-container ul li a:hover {
          color: #6f51ff; }
        .aside-content-container ul li a img {
          display: inline-block;
          width: 2.5rem;
          vertical-align: middle;
          margin-right: 1rem; }

@media screen and (max-width: 1024px) {
  .aside-content-container {
    margin: 0 1.5rem 0 0; } }

@media screen and (max-width: 768px) {
  .aside-content-container {
    height: auto;
    width: 100%;
    overflow: hidden;
    margin-bottom: 3.5rem; } }

.login-container {
  text-align: center;
  max-width: 30rem;
  width: 100%; }
  .login-container h2 {
    margin: 0 0 5rem 0; }
  .login-container form .input-container {
    position: relative;
    padding: 0 0 3rem 0;
    margin-bottom: 2rem; }
    .login-container form .input-container input {
      display: block;
      width: 100%;
      border: 0;
      border-bottom: .1rem solid black;
      line-height: 2rem;
      background: transparent; }
    .login-container form .input-container .error-message {
      position: absolute;
      bottom: 0;
      left: 0;
      color: red; }
  .login-container form button {
    margin: 1rem 0 0 0; }

.mobile-menu {
  display: none;
  position: fixed;
  top: 1.5rem;
  color: black;
  right: 2rem;
  z-index: 1000;
  cursor: pointer; }
  .mobile-menu > img {
    display: block;
    height: 3rem;
    width: auto; }

@media screen and (max-width: 1024px) {
  .mobile-menu {
    display: block; } }

